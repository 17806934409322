
// src/pages/Catalog/Catalog.js
import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import BeatCard from '../BeatCard/BeatCard';

function Catalog() {
  const [beats, setBeats] = useState([
    // Sample data, replace with actual beat data or API call
    { id: 1, title: 'Summer Vibes', producer: 'DJ Cool', genre: 'Hip Hop', price: 49.99, coverArt: 'path/to/cover1.jpg' },
    { id: 2, title: 'Midnight Groove', producer: 'BeatMaster', genre: 'R&B', price: 39.99, coverArt: 'path/to/cover2.jpg' },
  ]);

  const [currentBeat, setCurrentBeat] = useState(null);

  const handlePlay = (beat) => {
    setCurrentBeat(beat);
    // Implement logic to play the beat sample
  };

  return (
    <Container className="my-5">
      <h2>Beat Catalog</h2>
      <Row>
        {beats.map(beat => (
          <Col key={beat.id} md={4} className="mb-4">
            <BeatCard beat={beat} onPlay={handlePlay} />
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default Catalog;