import React, { useState } from 'react';
import { Container, Form, Button, Alert, Card } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { Calendar, Clock, User, Mail, Phone, FileText } from 'react-feather';
import axios from 'axios';
import './BookSession.css';

const fadeIn = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.6 }
};

// Replace these with your actual Telegram bot token and chat ID
const TELEGRAM_BOT_TOKEN = '7869282678:AAGQWREuKnHFK4c6PZCkGRpCz-AqUAdeo30';
const TELEGRAM_CHAT_ID = '1431600455';

function BookSession() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    sessionType: '',
    date: '',
    time: '',
    additionalInfo: ''
  });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const sendToTelegram = async (data) => {
    const message = `
New Studio Booking:
Name: ${data.name}
Email: ${data.email}
Phone: ${data.phone}
Session Type: ${data.sessionType}
Date: ${data.date}
Time: ${data.time}
Additional Info: ${data.additionalInfo}
    `;

    const telegramApiUrl = `https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`;
    
    await axios.post(telegramApiUrl, {
      chat_id: TELEGRAM_CHAT_ID,
      text: message
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      await sendToTelegram(formData);
      
      setShowConfirmation(true);
      setFormData({
        name: '',
        email: '',
        phone: '',
        sessionType: '',
        date: '',
        time: '',
        additionalInfo: ''
      });
    } catch (err) {
      console.error('Error submitting form:', err);
      setError('There was an error submitting your booking. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container className="my-5">
      <motion.div variants={fadeIn} initial="initial" animate="animate">
        <h2 className="text-center mb-4">Book Your Studio Session</h2>
        {showConfirmation && (
          <Alert variant="success" onClose={() => setShowConfirmation(false)} dismissible>
            Your session has been booked successfully! We'll contact you soon to confirm the details.
          </Alert>
        )}
        {error && (
          <Alert variant="danger" onClose={() => setError(null)} dismissible>
            {error}
          </Alert>
        )}
        <Card className="booking-card">
          <Card.Body>
            <Form onSubmit={handleSubmit} className="booking-form">
              <Form.Group className="mb-3">
                <Form.Label><User size={18} /> Name</Form.Label>
                <Form.Control 
                  type="text" 
                  name="name" 
                  value={formData.name} 
                  onChange={handleInputChange} 
                  required 
                  placeholder="Enter your full name"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label><Mail size={18} /> Email</Form.Label>
                <Form.Control 
                  type="email" 
                  name="email" 
                  value={formData.email} 
                  onChange={handleInputChange} 
                  required 
                  placeholder="Enter your email"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label><Phone size={18} /> Phone</Form.Label>
                <Form.Control 
                  type="tel" 
                  name="phone" 
                  value={formData.phone} 
                  onChange={handleInputChange} 
                  required 
                  placeholder="Enter your phone number"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label><FileText size={18} /> Session Type</Form.Label>
                <Form.Select 
                  name="sessionType" 
                  value={formData.sessionType} 
                  onChange={handleInputChange} 
                  required
                >
                  <option value="">Select a session type</option>
                  <option value="recording">Recording</option>
                  <option value="mixing">Mixing</option>
                  <option value="mastering">Mastering</option>
                </Form.Select>
              </Form.Group>
              <div className="row">
                <div className="col-md-6">
                  <Form.Group className="mb-3">
                    <Form.Label><Calendar size={18} /> Preferred Date</Form.Label>
                    <Form.Control 
                      type="date" 
                      name="date" 
                      value={formData.date} 
                      onChange={handleInputChange} 
                      required 
                    />
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="mb-3">
                    <Form.Label><Clock size={18} /> Preferred Time</Form.Label>
                    <Form.Control 
                      type="time" 
                      name="time" 
                      value={formData.time} 
                      onChange={handleInputChange} 
                      required 
                    />
                  </Form.Group>
                </div>
              </div>
              <Form.Group className="mb-3">
                <Form.Label><FileText size={18} /> Additional Information</Form.Label>
                <Form.Control 
                  as="textarea" 
                  rows={3} 
                  name="additionalInfo" 
                  value={formData.additionalInfo} 
                  onChange={handleInputChange} 
                  placeholder="Any special requests or details about your session"
                />
              </Form.Group>
              <Button 
                variant="primary" 
                type="submit" 
                className="w-100 mt-3" 
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Booking...' : 'Book Session'}
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </motion.div>
    </Container>
  );
}

export default BookSession;