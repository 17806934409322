// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import Home from './Components/Home/Home';
import Catalog from './Components/Catalog/Catalog';
import BookSession from './Components/BookSession/BookSession'
import BeatPlayer from './Components/BeatPlayer/BeatPlayer';

function App() {
  return (
    <Router>
      <div className="App" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <Header />
        <main style={{ flex: 1 }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/catalog" element={<Catalog />} />
            <Route path="/book-session" element={<BookSession />} />
          </Routes>
        </main>
        <BeatPlayer />
        <Footer />
      </div>
    </Router>
  );
}

export default App;