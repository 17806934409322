// src/components/Header/Header.js
import React, { useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import './Header.css';

const AnimatedNavItem = ({ children, ...props }) => (
  <motion.div
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.95 }}
  >
    {children}
  </motion.div>
);

function Header() {
  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar className="custom-navbar" variant="dark" expand="lg" expanded={expanded}>
      <Navbar.Brand as={Link} to="/" onClick={() => setExpanded(false)}>
        <motion.div
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
        >
          4K RECORDS
        </motion.div>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)} />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <AnimatedNavItem>
            <Nav.Link as={Link} to="/" onClick={() => setExpanded(false)} className="custom-nav-link">Home</Nav.Link>
          </AnimatedNavItem>
          <AnimatedNavItem>
            <Nav.Link as={Link} to="/catalog" onClick={() => setExpanded(false)} className="custom-nav-link">Catalog</Nav.Link>
          </AnimatedNavItem>
          <AnimatedNavItem>
            <Nav.Link as={Link} to="/book-session" onClick={() => setExpanded(false)} className="custom-nav-link">Book Session</Nav.Link>
          </AnimatedNavItem>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Header;