
// src/components/BeatPlayer/BeatPlayer.js
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

function BeatPlayer({ beat }) {
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
    // Implement actual audio playback logic here
  };

  if (!beat) return null;

  return (
    <div className="fixed-bottom bg-dark text-light p-2">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <strong>{beat.title}</strong> - {beat.producer}
        </div>
        <Button variant={isPlaying ? "danger" : "success"} onClick={togglePlay}>
          {isPlaying ? "Pause" : "Play"}
        </Button>
      </div>
    </div>
  );
}

export default BeatPlayer;
