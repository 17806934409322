// src/pages/Home/Home.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Music2, Mic2 } from 'lucide-react';
import './Home.css';

const fadeInUp = {
  initial: { opacity: 0, y: 60 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 1.2, ease: [0.6, -0.05, 0.01, 0.99] }
};

const staggerDelay = 0.2;

function Home() {
  return (
    <motion.div initial="initial" animate="animate">
      <div className="hero-section">
        <div className="hero-background"></div>
        <div className="hero-overlay"></div>
        <Container className="hero-content">
          <Row className="justify-content-center">
            <Col md={8}>
              <motion.h1 
                className="display-4 mb-4"
                variants={fadeInUp}
                transition={{ delay: staggerDelay }}
              >
                Your Premium Recording Studio
              </motion.h1>
              <motion.p 
                className="lead mb-5"
                variants={fadeInUp}
                transition={{ delay: staggerDelay * 2 }}
              >
                Create your next hit with our <span className='red-gradient'>Premium</span> beats and <span className='text-gradient'>Professional studio sessions</span>.
              </motion.p>
              <motion.div 
                className="button-container"
                variants={fadeInUp}
                transition={{ delay: staggerDelay * 3 }}
              >
                <Link to="/catalog">
                  <motion.button 
                    className="custom-button primary"
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    Browse Beats
                    <Music2 className="button-icon" size={18} />
                  </motion.button>
                </Link>
                <Link to="/book-session">
                  <motion.button 
                    className="custom-button secondary"
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    Book Session
                    <Mic2 className="button-icon" size={18} />
                  </motion.button>
                </Link>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </div>
    </motion.div>
  );
}

export default Home;