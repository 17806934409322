
// src/components/BeatCard/BeatCard.js
import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { motion } from 'framer-motion';

function BeatCard({ beat, onPlay }) {
  return (
    <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
      <Card>
        <Card.Img variant="top" src={beat.coverArt} />
        <Card.Body>
          <Card.Title>{beat.title}</Card.Title>
          <Card.Text>
            Producer: {beat.producer}
            <br />
            Genre: {beat.genre}
            <br />
            Price: ${beat.price}
          </Card.Text>
          <Button variant="primary" onClick={() => onPlay(beat)}>Play Sample</Button>
        </Card.Body>
      </Card>
    </motion.div>
  );
}

export default BeatCard;
